
$( document ).ready(function() {

	var mq = window.matchMedia( "(min-width: 1025px)" );

	if (mq.matches) {
		// alert('matches');
	} else {
		// alert('no match');
		$('#js-steps-wrapper').on('click', '.js-toggle-overlay', function(e) {	
			e.preventDefault();
			$(this).closest('.grid-item-content').find('.close-icon').toggleClass('active');
			$(this).closest('.grid-item-content').find('.info-overlay').toggleClass('active');
		});
	}

	

	$("#js-steps-wrapper").on('click', '.js-slide-panel', function(e) {
        e.preventDefault();
        var target = $(this).data('slideto');
        var panelUrl = 'step' + target + '.html';
 		console.log(panelUrl);

 		if($('#panel-' + target).length == 0) {
  			//it doesn't exist
  			// alert('finns inte');
  			$.get( panelUrl, function(data) {
	            $('#js-steps-wrapper').append(data);
	            $('html, body').animate({
		        	scrollTop: $( "div#panel-" + target).offset().top - 45

		        }, 400);
	        }).fail(function() {
	    		console.log('error message if any.');
	  		});
		}

 		// $.get( panelUrl, function(data) {
   //          $('#js-steps-wrapper').append(data);
   //          $('html, body').animate({
	  //       	scrollTop: $( "div#panel-" + target).offset().top - 45

	  //       }, 400);
   //      }).fail(function() {
   //  		console.log('error message if any.');
  	// 	});

        return false;
    });

});